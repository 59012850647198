<template>
    <div>
        <div>
            <a-select :default-value='currentId' style="width: 120px" @change="handleChange">
                <a-select-option v-for="(item,index) in  options" :value="item.uuid" :key='item.uuid'>
                   {{ index==0?'帮助文档':'用户协议' }} 
                </a-select-option>
            </a-select>
            
            <a-button type="primary" @click="submit" style="margin-left: 30px;">
                保存
            </a-button>
        </div>
        <div style="border: 1px solid #ccc;margin-top: 20px">
            <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig"
                :mode="mode" />
            <Editor style="height: 500px; overflow-y: hidden;" v-model="html" :defaultConfig="editorConfig" :mode="mode"
                @onCreated="onCreated" @onChange="onChange" @onDestroyed="onDestroyed" @onMaxLength="onMaxLength"
                @onFocus="onFocus" @onBlur="onBlur" @customAlert="customAlert" @customPaste="customPaste" />
        </div>
    </div>
</template>
<script>

import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { message } from 'ant-design-vue';

export default {
    components: { Editor, Toolbar },
    data() {
        let that=this;
        return {
            options: [{
                value: '帮助',
                label: 'help'
            }, {
                value: '用户协议',
                label: 'xx'
            }],
            currentId:'',
            value: '',
            editor: null,
            html: '',
            toolbarConfig: {},
            editorConfig: {
                 placeholder: '请输入内容...' ,
            MENU_CONF:{
                uploadImage:{
                    server: '/api/upload',
                    // 自定义上传
    async customUpload(file, insertFn) {  // TS 语法
        console.log('删除干',file,insertFn)
// 图片转base64
    const reader = new FileReader(); // 创建一个FileReader对象
    reader.onload = (e) => {
        console.log(e)
    let   url = e.target.result; // 读取结果保存到 Vue 组件的 data 中
      insertFn(url, '', '')
    };
    reader.onerror=(e)=>{
    console.log(e)
    }
    reader.readAsDataURL(file);
    // async customUpload(file, insertFn) {                   // JS 语法
        // file 即选中的文件
        // 自己实现上传，并得到图片 url alt href
        // 最后插入图片
      
    }
                }
            }

            },
            mode: 'default', // or 'simple'

        };
    },
    created() {
        console.log(123)

    },
    mounted() {
        this.loadxx();
        console.log()
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    },
    methods: {
        loadxx() {
            this.data = [];
            this.$axios({
                method: 'post',
                url: '/getArticle',
                headers: {
                    authorization: localStorage.getItem('token')
                },
                data: {
                   
                }
            }).then(res => {
                console.log(res);
                if (res.data.code == 200) {
                    let res1=res.data.data.data;
                    console.log(res1)
                    this.options = res1
                    this.currentId =res1[0].uuid
                    this.editor.setHtml(res1[0].content)
                    console.log(this.currentId)
                } else {
                    message.error(res.data.data.errMsg);
                }

            })
        },
        updatexx(obj) {
            console.log(obj)
            this.$axios({
                method: 'post',
                url: '/updateArticle',
                headers: {
                    authorization: localStorage.getItem('token')
                },
                data: {
                    ...obj
                }
            }).then(res => {
                console.log(res);
                if (res.data.code == 200) {
                    message.success('修改成功')
                } else {
                    message.error(res.data.data.errMsg);
                }

            })
        },
        onCreated(editor) {
            this.editor = Object.seal(editor)
            console.log('onCreated', editor)
        },
        insertText() {
            const editor = this.editor // 获取 editor 实例
            if (editor == null) return

            // 调用 editor 属性和 API
            editor.insertText('一段文字')
            console.log(editor.children)
        },
        onChange(editor) { console.log('onChange', editor.children) },
        onDestroyed(editor) { console.log('onDestroyed', editor) },
        onMaxLength(editor) { console.log('onMaxLength', editor) },
        onFocus(editor) { console.log('onFocus', editor) },
        onBlur(editor) { console.log('onBlur', editor) },
        customAlert(info, type) { window.alert(`customAlert in Vue demo\n${type}:\n${info}`) },
        customPaste(editor, event, callback) {
            console.log('ClipboardEvent 粘贴事件对象', event)
            const html = event.clipboardData.getData('text/html') // 获取粘贴的 html
            const text = event.clipboardData.getData('text/plain') // 获取粘贴的纯文本
            const rtf = event.clipboardData.getData('text/rtf') // 获取 rtf 数据（如从 word wsp 复制粘贴）

            // 自定义插入内容
            editor.insertText(text)

            // 返回 false ，阻止默认粘贴行为
            event.preventDefault()
            callback(false) // 返回值（注意，vue 事件的返回值，不能用 return）

            // 返回 true ，继续默认的粘贴行为
            // callback(true)
        },
        handleChange(value) {
            console.log(`selected ${value}`);
            let arr = this.options.filter(ele=>ele.uuid==value);

            this.currentId = value;
            this.editor.setHtml(arr[0].content)
        },
        submit() {
            let obj={
                id:this.currentId,

                content:this.editor.getHtml(),
            }
            this.updatexx(obj)
            console.log('保存',obj)
        }
    },
};
</script>
<style></style>