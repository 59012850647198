<template>
    <div>
        <div
            style="display: flex;justify-content: space-around;margin: 10px 0;background-color: #fff;padding: 20px 0 ;border-radius: 4px;">
            <div><a-input placeholder="请输入标题" v-model="messageSubject" /></div>
            <div><a-input placeholder="请输入联系人" v-model="contacts" /></div>
            <a-select default-value="99" style="width: 120px" @change="handleChange">
                <a-select-option value="帮忙">
                    帮忙
                </a-select-option>
                <a-select-option value="求职招聘">
                    求职招聘
                </a-select-option>
                <a-select-option value="房屋租赁">
                    房屋租赁
                </a-select-option>
                <a-select-option value="闲置">
                    闲置
                </a-select-option>
                <a-select-option value="其他">
                    其他
                </a-select-option>
                <a-select-option value="99">
                    全部
                </a-select-option>
            </a-select>
            <div>省市区：
                <a-cascader :options="level" placeholder="请选择" @change="onChange"
                    :field-names="{ label: 'name', value: 'name', children: 'children' }" />
            </div>
            <div>有效时间：
                <a-date-picker v-decorator="['date-time-picker', config]" v-model="time" show-time
                    format="YYYY-MM-DD HH:mm:ss" />
            </div>
            <a-button type="primary" html-type="submit" @click="toSearch">
                搜索
            </a-button>
        </div>
        <a-table :data-source="data" :bordered="bordered" :scroll="{ x: 1500, y: 600 }">
            <a-table-column key="index" title="序号" data-index="index" />
            <a-table-column key="messageSubject" data-index="messageContent">
                <span slot="title" style="color: #1890ff">标题</span>
            </a-table-column>
            <a-table-column key="messageContent" title="描述" data-index="messageSubject" />
            <a-table-column key="contacts" title="联系人" data-index="contacts" />
            <a-table-column key="phone" title="电话号码" data-index="phone" />
            <a-table-column key="timec" title="有效时间" data-index="timec" />
            <a-table-column key="address" title="地址" data-index="address" />
            <a-table-column key="type" title="类型" data-index="type">
                <template slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys }">
                    <div style="background-color: white; border: solid;">
                        <a-checkbox v-for="tag in ['帮忙', '求职招聘', '房屋租赁', '闲置', '其他']" :key="tag"
                            :checked="filteredTags.includes(tag)"
                            @change="filteredTags = toggleSelectedTags(filteredTags, tag, $event.target.checked)">
                            {{ tag }}
                        </a-checkbox>
                    </div>
                </template>
                <template slot-scope="tags">
                    <span>
                        <a-tag color="blue">{{ tags }}</a-tag>
                    </span>
                </template>
            </a-table-column>
            <a-table-column key="cameraArr" title="相关图片" data-index="cameraArr">
                <template slot-scope="tags">
                    <div>
                        <img v-for="tag in tags" :key="tag"
                            :src="tag | imgFilter"
                            alt="" style="width:40px;height: 40px;margin: 10px;background-color: red;cursor: pointer;">
                        <!-- <a-tag color="blue">{{ tags }}</a-tag> -->
                    </div>
                </template>
            </a-table-column>
            <!-- <a-table-column key="action" title="功能">
            <template slot-scope="text, record">
                <span>
                    <a>审核</a>
                    <a-divider type="vertical" />
                    <a>删除</a>
                </span>
            </template>
        </a-table-column> -->
        </a-table>
    </div>
</template>
<script>
const data = [
    {
        key: '1',
        firstName: 'John',
        lastName: 'Brown',
        age: 32,
        address: 'New York No. 1 Lake Park',
        tags: ['网店'],
    }
];
const { province, city, area, level } = require('province-city-china/data');
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}
//province - 省级(省/直辖市/特别行政区)  city - 地级(城市) area - 县级(区县) town - 乡级(乡镇/街)
export default {
    name: 'Vone',
    data() {
        return {
            bordered: true,
            data,
            filteredTags: [],
            tagsFilterDropdownVisible: false,
            level: [],
            abc: [],
            messageSubject: '',//标题搜索
            contacts: '',
            time: '',
            config: {},
            type:''

        };
    },
    created() {
        console.log(province)
        // 数据组合
        // province, city, area
        // this.province = Object.freeze(province);
        this.level = Object.freeze(level);
        // this.city = city;
        // this.area = area;
        let obj = {
            messageSubject: '',//标题搜索
            province: '',
            city: '',
            area: '',
            contacts: '',
            time: 0,
            type:''
        };
        this.loadxx(obj);
    },
    methods: {
        handleChange(e){
            this.type=e;
            console.log(e)
        },
        toSearch() {
            let obj = {
                messageSubject: this.messageSubject,//标题搜索
                contacts: this.contacts,
                province: this.abc.length >= 1 ? this.abc[0] : '',
                city: this.abc.length >= 2 ? this.abc[1] : '',
                area: this.abc.length >= 3 ? this.abc[2] : '',
                time: this.$moment(this.time).valueOf(),
                type: this.type==99?'':this.type,
            };
            console.log(obj);
            this.loadxx(obj);
        },
        loadxx(obj) {
            this.data = [];
            this.$axios({
                method: 'post',
                url: '/getWebXx',
                headers: {
                    authorization: localStorage.getItem('token')
                },
                data: {
                    ...obj
                }
            }).then(res => {
                console.log(res);
                if (res.data.code == 200) {
                    res.data.data.data.forEach((ele, index) => {
                        ele.index = index + 1;
                        ele.timec = this.getNowTime(ele.time);
                        console.log(ele.timec)
                    })
                    this.data = res.data.data.data
                } else {
                    message.error(res.data.data.errMsg);
                }

            })
        },
        //获取当前时间
        getNowTime(timec) {
            if (Number.isNaN(Number(timec))) {
                return timec
            }
            var date = new Date(Number(timec));
            //年 getFullYear()：四位数字返回年份
            var year = date.getFullYear(); //getFullYear()代替getYear()
            //月 getMonth()：0 ~ 11
            var month = date.getMonth() + 1;
            //日 getDate()：(1 ~ 31)
            var day = date.getDate();
            //时 getHours()：(0 ~ 23)
            var hour = date.getHours();
            //分 getMinutes()： (0 ~ 59)
            var minute = date.getMinutes();
            //秒 getSeconds()：(0 ~ 59)
            var second = date.getSeconds();

            var time = year + '-' + this.addZero(month) + '-' + this.addZero(day) + ' ' + this.addZero(hour) + ':' + this.addZero(minute) + ':' + this.addZero(second);
            return time;
        },
        //小于10的拼接上0字符串
        addZero(s) {
            return s < 10 ? ('0' + s) : s;
        },

        onChange(value) {
            this.abc = value;
            console.log(value);
        },
        toggleSelectedTags(oldTags, tag, checked) {
            let newTags = [...oldTags];
            if (checked) {
                newTags.push(tag);
            } else {
                newTags = newTags.filter(t => t != tag);
            }
            return newTags;
        },
    },
};
</script>
<style scoped lang="less">
#form1 /deep/ .ant-form-item-label {

    margin-right: 1.6% !important;

}
</style>
