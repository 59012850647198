<template>
    <div>
        <a-form-model class="ant-advanced-search-form" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol"
            style="width:100%;height: 100%;">
            <a-row :gutter="24">
                <a-col :span="12">
                    <a-form-model-item label="商品名称">
                        <a-input v-model="form.name" />
                    </a-form-model-item>
                </a-col>
                <a-col :span="12">
                    <a-form-model-item label="副标题">
                        <a-input v-model="form.name2" />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col :span="12">
                    <a-form-model-item label="电话">
                        <a-input v-model="form.tel" default-value="0571" type='number' />
                    </a-form-model-item>
                </a-col>
                <a-col :span="12">

                    <a-form-model-item label="发货方式">
                        <a-select default-value="lucy" style="width: 120px" @change="handleChange">
                            <a-select-option value="jack">
                                本地
                            </a-select-option>
                            <a-select-option value="lucy">
                                其他
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col :span="12">
                    <a-form-model-item label="是否预售">
                        <a-radio-group v-model="form.name11" @change="onChange">
                            <a-radio :value="1">
                                是
                            </a-radio>
                            <a-radio :value="2">
                                否
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                </a-col>
                <a-col :span="12">
                    <a-form-model-item label="副标题">
                        <a-input v-model="form.name2" />
                    </a-form-model-item>
                </a-col>
            </a-row>


            <a-row :gutter="24">
                <a-col :span="12">
                    <a-form-model-item label="价格">
                        <a-input v-model="form.name3" />
                    </a-form-model-item>
                </a-col>
                <a-col :span="12">

                </a-col>
            </a-row>
        </a-form-model>
        <a-form-model :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }" :model="form" id="form1">
            <a-form-model-item label="商品头部图片">
                <a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" action=""
                    :before-upload="beforeUpload" @change="handleChange5">
                    <img v-if="form.imageUrl" style="width:120px;height:120px;" :src="form.imageUrl" alt="avatar" />
                    <div v-else>
                        <a-icon :type="loading ? 'loading' : 'plus'" />
                        <div class="ant-upload-text">
                            上传图片
                        </div>
                    </div>
                </a-upload>
                <span style="font-size:14px;color:#999;">主图，多张，最少1张，最多9张</span>
            </a-form-model-item>
            <a-form-model-item label="商品库存">
                <a-button type="primary" @click="onaddsku" style="margin-bottom:10px;">
                    添加商品库存
                </a-button>
                <a-table :columns="columns3" :data-source="data3">
                    <a slot="name" slot-scope="text">{{ text }}</a>
                    <span slot="customTitle"><a-icon type="smile-o" /> Name</span>
                    <span slot="tags" slot-scope="tags">
                        <div>
                            <img v-for="tag in tags" :key="tag"
                                style="width:120px;height:120px;margin-right: 10px;margin-bottom:10px;border-radius: 5px;"
                                src="https://mmecimage.cn/p/wx37f38d59298839c3/HJE9eJaEc5bJk-eaArVdILSB7MMaHgdK2-JIn51nMQ" />
                        </div>
                    </span>
                    <span slot="action" slot-scope="text, record">
                        <a>删除</a>
                        <a-divider type="vertical" />
                        <a class="ant-dropdown-link"> 编辑</a>
                    </span>
                </a-table>
            </a-form-model-item>
            <a-form-model-item label="商品类目">
                <a-input-search placeholder="输入商品类目名称" enter-button="新增商品类目名称" @search="onaddtype" />
                <br />
                <a-row>
                    <a-col :span="4" style="color:#666;">
                        选择商品类目<span style="font-size:14px;color:#999;"></span> :
                    </a-col>
                    <a-col :span="20">
                        <a-select mode="multiple" :size="size" placeholder="Please select" :default-value="['a1', 'b2']"
                            style="width: 50%" @change="handleChange" @popupScroll="popupScroll">
                            <a-select-option v-for="i in 25" :key="(i + 9).toString(36) + i">
                                {{ (i + 9).toString(36) + i }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                </a-row>

            </a-form-model-item>
            <a-form-model-item label="商品属性">
                <a-button type="primary" @click="onaddAttr" style="margin-bottom:10px;">
                    添加商品属性
                </a-button>
                <a-table :columns="columns2" :data-source="data2">
                    <a slot="name" slot-scope="text">{{ text }}</a>
                    <span slot="customTitle"><a-icon type="smile-o" /> Name</span>
                    <span slot="tags" slot-scope="tags">
                        <a-tag v-for="tag in tags" :key="tag"
                            :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'">
                            {{ tag.toUpperCase() }}
                        </a-tag>
                    </span>
                    <span slot="action" slot-scope="text, record">
                        <a>删除</a>
                        <a-divider type="vertical" />
                        <a class="ant-dropdown-link"> 编辑</a>
                    </span>
                </a-table>
            </a-form-model-item>
            <a-form-model-item label="商品详情信息">
                <a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" action=""
                    :before-upload="beforeUpload" @change="handleChange5">
                    <img v-if="form.imageUrl" style="width:120px;height:120px;" :src="form.imageUrl" alt="avatar" />
                    <div v-else>
                        <a-icon :type="loading ? 'loading' : 'plus'" />
                        <div class="ant-upload-text">
                            上传详情图片
                        </div>
                    </div>
                </a-upload>
                <span style="font-size:14px;color:#999;">最多支持9张</span>
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                <a-button type="primary" @click="onSubmit">
                    新增
                </a-button>
                <a-button style="margin-left: 10px;">
                    取消
                </a-button>
            </a-form-model-item>
        </a-form-model>
        <!-- 添加商品属性 -->
        <a-modal v-model="shopvisible" title="商品属性" on-ok="handleOk">
            <a-form :form="form1" :label-col="{ span: 5 }" style="width:100%;" :wrapper-col="{ span: 16 }"
                @submit="handleSubmit">
                <a-form-item label="属性名">
                    <a-input placeholder="输入商品属性" />
                </a-form-item>
                <a-form-item label="属性值">
                    <a-input placeholder="输入商品值" />
                </a-form-item>
            </a-form>
            <template slot="footer">
                <a-button key="back" @click="handleCancel">
                    取消
                </a-button>
                <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
                    确认
                </a-button>
            </template>
        </a-modal>
        <!-- 添加商品sku -->
        <a-modal v-model="skuvisible" title="商品sku" on-ok="handleOk" width="70%" dialogClass="ccc">
            <a-form :form="form1" :label-col="{ span: 5 }" style="width:100%;" :wrapper-col="{ span: 16 }"
                @submit="handleSubmit">
                <a-form-model-item label="sku小图">
                    <a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
                        action="" :before-upload="beforeUpload" @change="handleChange5">
                        <img v-if="form.imageUrl" style="width:120px;height:120px;" :src="form.imageUrl" alt="avatar" />
                        <div v-else>
                            <a-icon :type="loading ? 'loading' : 'plus'" />
                            <div class="ant-upload-text">
                                上传sku小图
                            </div>
                        </div>
                    </a-upload>
                    <span style="font-size:14px;color:#999;">只支持一张</span>
                </a-form-model-item>
                <a-form-item label="数量">
                    <a-input placeholder="输入数量" />
                </a-form-item>
                <a-form-item label="价格">
                    <a-input placeholder="输入价格" />
                </a-form-item>
                <a-form-item label="价格">
                    <a-button type="primary" @click="onSubmit1" style="margin-bottom:10px;">
                        新增sku属性
                    </a-button>
                    <a-table :columns="columns" :data-source="data1" bordered tableLayout="fixed">
                        <template v-for="col in ['name', 'age', 'address']" :slot="col" slot-scope="text, record, index">
                            <div :key="col">
                                <a-input v-if="record.editable" style="margin: -5px 0" :value="text"
                                    @change="e => handleChange9(e.target.value, record.key, col)" />
                                <template v-else>
                                    {{ text }}
                                </template>
                            </div>
                        </template>
                        <template slot="operation" slot-scope="text, record, index">
                            <div class="editable-row-operations">
                                <span v-if="record.editable">
                                    <a @click="() => save9(record.key)">保存</a>
                                    <a-popconfirm title="Sure to cancel?" @confirm="() => cancel9(record.key)">
                                        <a>取消</a>
                                    </a-popconfirm>
                                </span>
                                <span v-else>
                                    <a :disabled="editingKey !== ''" @click="() => edit9(record.key)">编辑</a>
                                </span>
                            </div>
                        </template>
                    </a-table>
                </a-form-item>

            </a-form>
            <template slot="footer" style="text-align:;">
                <a-button key="back" @click="handleCancel">
                    取消
                </a-button>
                <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
                    确认
                </a-button>
            </template>
        </a-modal>
    </div>
</template>
<script>
const data = [
    {
        "thumb_img": "https://mmecimage.cn/p/wx37f38d59298839c3/HJE9eJaEc5bJk-eaArVdILSB7MMaHgdK2-JIn51nMQ",
        "sale_price": 1300,
        "stock_num": 100,
        "sku_code": "A24525252",
        "sku_attrs": [
            {
                "attr_key": "选择颜色",
                "attr_value": "红蓝主机"
            },
            {
                "attr_key": "选择套装",
                "attr_value": "主机+保护套"
            }
        ],
        "sku_deliver_info": {
            "stock_type": 0
        }
    },
    {
        "thumb_img": "https://mmecimage.cn/p/wx37f38d59298839c3/HJE9eJaEc5bJk-eaArVdILSB7MMaHgdK2-JIn51nMQ",
        "sale_price": 1300,
        "stock_num": 100,
        "sku_code": "A24525252",
        "sku_attrs": [
            {
                "attr_key": "选择颜色",
                "attr_value": "红蓝主机"
            },
            {
                "attr_key": "选择套装",
                "attr_value": "主机+保护套"
            }
        ],
        "sku_deliver_info": {
            "stock_type": 0
        }
    }
];
const columns = [
    {
        title: '属性名',
        dataIndex: 'name',
        width: '30%',
        scopedSlots: { customRender: 'name' },
    },
    {
        title: '属性值',
        dataIndex: 'address',
        width: '30%',
        scopedSlots: { customRender: 'age' },
    },
    {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
    },
];
const data1 = [];
for (let i = 0; i < 10; i++) {
    data1.push({
        key: i.toString(),
        name: `属性名 ${i}`,
        address: `属性值 ${i}`,
    });
}
const columns2 = [
    {
        title: '属性名',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '属性值',
        dataIndex: 'age',
        key: 'age',
    },
    {
        title: '',
        key: 'action',
        scopedSlots: { customRender: 'action' },
    },
];

const data2 = [
    {
        key: '1',
        name: 'John Brown',
        age: 32,
    },
    {
        key: '2',
        name: 'Jim Green',
        age: 42,
    },
    {
        key: '3',
        name: 'Joe Black',
        age: 32,
    },
];
const columns3 = [
    {
        title: '价格',
        dataIndex: 'age',
        key: 'age',
    },
    {
        title: '数量',
        dataIndex: 'age',
        key: 'address',
    },
    {
        title: '图片',
        key: 'tags',
        dataIndex: 'tags',
        scopedSlots: { customRender: 'tags' },
    },
    {
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: 'action' },
    },
];

const data3 = [
    {
        key: '1',
        name: 'John Brown',
        age: 32,
        address: 'New York No. 1 Lake Park',
        tags: ['nice', 'developer'],
    },
    {
        key: '2',
        name: 'Jim Green',
        age: 42,
        address: 'London No. 1 Lake Park',
        tags: ['loser'],
    },
    {
        key: '3',
        name: 'Joe Black',
        age: 32,
        address: 'Sidney No. 1 Lake Park',
        tags: ['cool', 'teacher'],
    },
];
const { province, city, area } = require('province-city-china/data');
let province1 = '';
let city1 = '';
let area1 = '';
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}
//province - 省级(省/直辖市/特别行政区)  city - 地级(城市) area - 县级(区县) town - 乡级(乡镇/街)
export default {
    name: 'one',
    data() {
        this.cacheData = data1.map(item => ({ ...item }));
        return {
            editingKey: '',
            data1,
            columns,
            data2,
            columns2,
            data3,
            columns3,
            shopvisible: false,
            skuvisible: false,
            size: 'small',
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
            options: [],
            skuvalue: '',
            attrvalue: '',
            typevalue: '',
            skus: [],
            attrs: [],
            types: [],
            form1: {
                attr_key: '',
                attr_value: ''
            },
            form: {
                name: '',
                name1: '',
                name2: '',
                name3: '',
                name4: '',
                name5: '',
                name6: '',
                name7: '',
                name8: '',
                name11: '',
                tel: '',
                type: '00',
                region: undefined,
                date1: undefined,
                delivery: false,
                type: [],
                resource: '',
                desc: '',
                imageUrl: '',
            },
            province: [],
            city: [],
            area: [],
            loading: false,
            data,
            filteredTags: [],
            tagsFilterDropdownVisible: false,
        };
    },
    created() {
        console.log(province)
        // 数据组合
        // province, city, area
        this.province = Object.freeze(province);
        // this.city = city;
        // this.area = area;
        this.skus = data
    },
    methods: {
        handleChange9(value, key, column) {
            const newData = [...this.data1];
            const target = newData.find(item => key === item.key);
            if (target) {
                target[column] = value;
                this.data1 = newData;
            }
        },
        edit9(key) {
            const newData = [...this.data1];
            const target = newData.find(item => key === item.key);
            this.editingKey = key;
            if (target) {
                target.editable = true;
                this.data1 = newData;
            }
        },
        save9(key) {
            const newData = [...this.data1];
            const newCacheData = [...this.cacheData];
            const target = newData.find(item => key === item.key);
            const targetCache = newCacheData.find(item => key === item.key);
            if (target && targetCache) {
                delete target.editable;
                this.data1 = newData;
                Object.assign(targetCache, target);
                this.cacheData = newCacheData;
            }
            this.editingKey = '';
        },
        cancel9(key) {
            const newData = [...this.data1];
            const target = newData.find(item => key === item.key);
            this.editingKey = '';
            if (target) {
                Object.assign(target, this.cacheData.find(item => key === item.key));
                delete target.editable;
                this.data1 = newData;
            }
        },
        onSubmit1(e) {
            console.log('新增sku属性')
        },
        handleCancel(e) {
            console.log('新增属性')
            this.shopvisible = false;
            this.skuvisible = false
        },
        handleSubmit(e) {
            console.log('handleSubmit')
            this.shopvisible = false;
            this.skuvisible = false
        },
        handleOk(e) {
            console.log('handleSubmit')
            this.shopvisible = false;
            this.skuvisible = false
        },
        handleFormLayoutChange(e) {
            console.log(e.target.value)
            this.form1 = e.target.value;
        },
        addattrsname() {
            console.log('新增属性')
        },
        toggleSelectedTags(oldTags, tag, checked) {
            let newTags = [...oldTags];
            if (checked) {
                newTags.push(tag);
            } else {
                newTags = newTags.filter(t => t != tag);
            }
            return newTags;
        },
        onaddsku() {
            console.log('111')
            this.skuvisible = true;
        },
        onaddtype() { console.log('111') },
        onSubmit() {
            console.log('submit!', this.form);
        },
        handleChange(value) {
            this.type = value;
            console.log(`selected ${value}`);
        },
        onaddAttr() {
            console.log('添加商品属性')
            this.shopvisible = true;
        },
        onChange(value) {
            console.log(value);
        },
        handleChange5(info) {
            console.log(info)
            if (info.file.status === 'uploading') {
                this.loading = true;
                return;
            }
            if (info.file.status === 'done') {
                // Get this url from response in real world.
                getBase64(info.file.originFileObj, imageUrl => {
                    this.form.imageUrl = imageUrl;
                    this.loading = false;
                });
            }

        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                this.$message.error('You can only upload JPG file!');
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('Image must smaller than 2MB!');
            }
            console.log(file)
            this.form.imageUrl = URL.createObjectURL(file);
            this.loading = false;
            return isJpgOrPng && isLt2M;
        },
        popupScroll() {
            console.log('popupScroll');
        },
    },
};
</script>
<style lang="less">
#form1 /deep/ .ant-form-item-label {

    margin-right: 1.6% !important;

}

.ccc {
    top: 34px !important;
}

.ant-advanced-search-form {
    padding: 24px !important;
}
</style>
