<template>
    <div>
        <div class="fc">
            <div style=" background: linear-gradient(to bottom right,#bc95c6, #7dc4cc);">
                <h3>本月新增商品数</h3>
                <div>{{ a }} 个</div>

            </div>
            <div style=" background: linear-gradient(to bottom right,#4da2cb, #67b26f);">
                <h3>本月新增信息</h3>
                <div>{{ b }}个</div>
            </div>
            <div style=" background: linear-gradient(to bottom right,#eec9a3, #ef629f);">
                <h3>本月新增出行</h3>
                <div>{{ c }}个</div>
            </div>
            <div style=" background: linear-gradient(to bottom right,#4da2cb, #67b26f);">
                <h3>本月新增用户数</h3>
                <div>{{ d }}人</div>
            </div>
        </div>
        <section class="chart">
            <a-row>
                <a-col :span="12">
                    <div id="chartColumn" style="width:100%; height:400px;"></div>
                </a-col>
                <a-col :span="12">
                    <div id="chartBar" style="width:100%; height:400px;"></div>
                </a-col>
                <!-- <a-col :span="12">
                <div id="chartLine" style="width:100%; height:400px;"></div>
                </a-col>
                <a-col :span="12">
                <div id="chartPie" style="width:100%; height:400px;"></div>
                </a-col> -->
            </a-row>
        </section>
    </div>
</template>

<script>
import echarts from 'echarts'

export default {
    name: 'Vecharts',
    data() {
        return {
            name: '',
            chartColumn: null,
            chartBar: null,
            chartLine: null,
            chartPie: null,
            all: {},
            a: 0, b: 0, c: 0, d: 0
        }
    },
    methods: {
        create1() {
            this.chartColumn.setOption({
                title: { text: '用户月份统计' },
                tooltip: {},
                xAxis: {
                    data: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"]
                },
                yAxis: {},
                series: [{
                    name: '用户数',
                    type: 'bar',
                    data: this.all.user
                }]
            });
        },
        create2() {
            let newArr=[],newArr1=[],newArr2=[];
            newArr[0]=this.all.shop[0]+this.all.shop[1]+this.all.shop[2]
            newArr[1]=this.all.shop[4]+this.all.shop[5]+this.all.shop[3]
            newArr[2]=this.all.shop[8]+this.all.shop[9]+this.all.shop[7]
            newArr[3]=this.all.shop[10]+this.all.shop[11]+this.all.shop[12];
            newArr1[0]=this.all.xx[0]+this.all.xx[1]+this.all.xx[2]
            newArr1[1]=this.all.xx[4]+this.all.xx[5]+this.all.xx[3]
            newArr1[2]=this.all.xx[8]+this.all.xx[9]+this.all.xx[7]
            newArr1[3]=this.all.xx[10]+this.all.xx[11]+this.all.xx[12];
            newArr2[0]=this.all.travel[0]+this.all.travel[1]+this.all.travel[2]
            newArr2[1]=this.all.travel[4]+this.all.travel[5]+this.all.travel[3]
            newArr2[2]=this.all.travel[8]+this.all.travel[9]+this.all.travel[7]
            newArr2[3]=this.all.travel[10]+this.all.travel[11]+this.all.travel[12];
            this.chartBar.setOption({
                title: {
                    text: '季度新增信息',
                    subtext: '今年'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    data: ['商铺', '信息', '出行']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    data: ['一季度', '二季度', '三季度', '四季度']
                },
                series: [
                    {
                        name: '商铺',
                        type: 'bar',
                        data: newArr
                    },
                    {
                        name: '信息',
                        type: 'bar',
                        data: newArr1
                    },
                    {
                        name: '出行',
                        type: 'bar',
                        data: newArr2
                    }
                ]
            });
        },
    },
    mounted: function () {
        var _this = this;
        localStorage.getItem('name');
        //基于准备好的dom，初始化echarts实例
        this.chartColumn = echarts.init(document.getElementById('chartColumn'));
        this.chartBar = echarts.init(document.getElementById('chartBar'));
        // this.chartLine = echarts.init(document.getElementById('chartLine'));
        // this.chartPie = echarts.init(document.getElementById('chartPie'));
        let time = new Date();
        let month = time.getMonth();
        console.log(month);

        this.$axios({
            method: 'post',
            url: '/getWebAll',
            headers: {
                authorization: localStorage.getItem('token')
            },
            data: {}
        }).then(res => {
            console.log(res.data.data.data);
            if (res.data.code == 200) {
                this.all = res.data.data.data
                this.curIndex = month;
                this.a = res.data.data.data.shop[month];
                this.b = res.data.data.data.xx[month];
                this.c = res.data.data.data.travel[month];
                this.d = res.data.data.data.user[month];
                console.log(this.a, this.b, this.c, this.d)
                this.create1();
                this.create2();
            } else {
                message.error(res.data.data.errMsg);
            }

        })





        // this.chartLine.setOption({
        //     title: {
        //         text: 'Line Chart'
        //     },
        //     tooltip: {
        //         trigger: 'axis'
        //     },
        //     legend: {
        //         data: ['邮件营销', '联盟广告', '搜索引擎']
        //     },
        //     grid: {
        //         left: '3%',
        //         right: '4%',
        //         bottom: '3%',
        //         containLabel: true
        //     },
        //     xAxis: {
        //         type: 'category',
        //         boundaryGap: false,
        //         data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
        //     },
        //     yAxis: {
        //         type: 'value'
        //     },
        //     series: [
        //         {
        //             name: '邮件营销',
        //             type: 'line',
        //             stack: '总量',
        //             data: [120, 132, 101, 134, 90, 230, 210]
        //         },
        //         {
        //             name: '联盟广告',
        //             type: 'line',
        //             stack: '总量',
        //             data: [220, 182, 191, 234, 290, 330, 310]
        //         },
        //         {
        //             name: '搜索引擎',
        //             type: 'line',
        //             stack: '总量',
        //             data: [820, 932, 901, 934, 1290, 1330, 1320]
        //         }
        //     ]
        // });

        // this.chartPie.setOption({
        //     title: {
        //         text: 'Pie Chart',
        //         subtext: '纯属虚构',
        //         x: 'center'
        //     },
        //     tooltip: {
        //         trigger: 'item',
        //         formatter: "{a} <br/>{b} : {c} ({d}%)"
        //     },
        //     legend: {
        //         orient: 'vertical',
        //         left: 'left',
        //         data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎']
        //     },
        //     series: [
        //         {
        //             name: '访问来源',
        //             type: 'pie',
        //             radius: '55%',
        //             center: ['50%', '60%'],
        //             data: [
        //                 { value: 335, name: '直接访问' },
        //                 { value: 310, name: '邮件营销' },
        //                 { value: 234, name: '联盟广告' },
        //                 { value: 135, name: '视频广告' },
        //                 { value: 1548, name: '搜索引擎' }
        //             ],
        //             itemStyle: {
        //                 emphasis: {
        //                     shadowBlur: 10,
        //                     shadowOffsetX: 0,
        //                     shadowColor: 'rgba(0, 0, 0, 0.5)'
        //                 }
        //             }
        //         }
        //     ]
        // });
    }

}
</script>

<style scoped>
.chart {
    width: 100%;
    float: left;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    background-color: antiquewhite;
}

/*.chart div {
        height: 400px;
        float: left;
    }*/

.Col {
    padding: 20px 10px;
}

.fc>div {
    text-align: left;
    width: 22%;
    height: 110px;
    background-color: aquamarine;
    border-radius: 10px;
    padding-left: 3%;
    padding-top: 12px;
}

.fc h3 {
    color: #fff;
}

.fc {
    margin-bottom: 15px;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
</style>