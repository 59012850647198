import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import echarts from '../one/echarts.vue'
import one from '../one/one.vue'
import two from '../one/two.vue'
import three from '../one/user.vue'
import four from '../one/permison.vue'
import five from '../one/five.vue'
import six from '../one/six.vue'
import Home from '../one/Home.vue'
import NotFound from '../one/404.vue'
import Login from '../one/Login.vue'
import Xx from '../one/xx.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/login',
        component: Login,
        name: '',
        hidden: true
    },
    {
        path: '/',
        component: Home,
        name: '汇总',
        iconCls: 'ios-home',
        children: [
            { path: '/home', component: echarts, name: '汇总' },
        ]
    },
    //{ path: '/main', component: Main },
    {
        path: '/',
        component: Home,
        name: '需求',
        iconCls: 'ios-home', //图标样式class
        children: [
            { path: '/one', component: one, name: '发布需求' },
            { path: '/two', component: two, name: '需求列表' },
            // { path: '/main', component: Main, name: '主页', hidden: true },
            // { path: '/table', component: Table, name: '表格' },
            // { path: '/form', component: Form, name: '表单' },
            // { path: '/user', component: user, name: '列表' },
        ]
    },
    {
        path: '/',
        component: Home,
        name: '用户管理',
        iconCls: 'ios-paw',
        children: [
            { path: '/three', component: three, name: '用户管理' },
            { path: '/four', component: four, name: '管理授权' },
            // { path: '/page4', component: Page4, name: '用户管理' },
            // { path: '/page5', component: Page5, name: '管理授权' }
        ]
    },
    {
        path: '/',
        component: Home,
        name: '商品信息',
        iconCls: 'social-freebsd-devil',
        children: [
            { path: '/five', component: five, name: '新增商品' },
            { path: '/six', component: six, name: '商品列表' },
            // { path: '/page6', component: Page6, name: '新增商品' },
            // { path: '/page6', component: Page6, name: '商品列表' }
        ]
    },
    {
        path: '/',
        component: Home,
        name: '协议',
        iconCls: 'social-freebsd-devil',
        children: [
            { path: '/xx', component: Xx, name: '小程序端协议' },
            // { path: '/page6', component: Page6, name: '新增商品' },
            // { path: '/page6', component: Page6, name: '商品列表' }
        ]
    },
    {
        path: '*',
        component: NotFound,
        name: '',
        hidden: true
    },

]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router