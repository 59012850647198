<template>
    <div>
        <div
            style="display: flex;justify-content: space-around;margin: 10px 0;background-color: #fff;padding: 20px 0 ;border-radius: 4px;">
            <div><a-input placeholder="请输入姓名" v-model="nickName" /></div>
            <div><a-input placeholder="请输入电话号码" v-model="phone" type="number"/></div>
            <div>注册时间：
                <a-date-picker v-decorator="['date-time-picker', config]" v-model="time" show-time
                    format="YYYY-MM-DD HH:mm:ss" />
            </div>
            <a-button slot="enterButton" type="primary" @click="toSearch">
                搜索
            </a-button>
        </div>
        <a-table :columns="columns" :data-source="data" :scroll="{ x: 1500, y: 600 }">
            <a slot="img" slot-scope="text"><img :src="text" style="width:40px;height: 40px;border-radius: 4px;"></a>
            <a slot="role" slot-scope="text"><span :style="{ color: text == true ? '#e4393c' : 'green' }">{{ text == true ? '是' : '否'
            }}</span> </a>
            <a slot="gender" slot-scope="text">{{ text == 0 ? '女' : '男' }}</a>
            <a slot="action" slot-scope="text,record,index" @click="showModal(text, record, index)">授权</a>
        </a-table>

        <!-- 遮罩层 -->
        <a-modal v-model="visible" :title="'授权【' + curItem.nickName + '】'" ok-text="确认" cancel-text="取消" @ok="hideModal">
            <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 11 }">
                <a-form-item label="管理区域">
                    <a-cascader :options="level" placeholder="请选择" @change="onChange1" v-model="form.adminXX"
                        :field-names="{ label: 'name', value: 'name', children: 'children' }" />
                </a-form-item>
                <a-form-item label="授权管理员">
                    <a-switch @change="onChange2" v-model="form.isAdmin" />
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>
<script>
const { level } = require('province-city-china/data');
const columns = [
    { title: '序号', dataIndex: 'index', key: 'index', width: 150 },
    { title: '用户头像', dataIndex: 'avatarUrl', key: 'avatarUrl', width: 120, scopedSlots: { customRender: 'img' } },
    { title: '姓名', width: 100, dataIndex: 'nickName', key: 'nickName', fixed: 'left' },
    { title: '管理员', dataIndex: 'isAuth', key: 'isAuth', width: 60, scopedSlots: { customRender: 'role' } },
    { title: '联系方式', width: 100, dataIndex: 'phone', key: 'age', fixed: 'left' },
    { title: '注册时间', dataIndex: 'registrationTime', key: 'registrationTime', width: 150 },
    { title: '地址', dataIndex: 'province', key: 'province', width: 150 },
    { title: '性别', dataIndex: 'gender', key: 'gender', width: 150, scopedSlots: { customRender: 'gender' }, },
    {
        title: '操作',
        key: 'operation',
        fixed: 'right',
        width: 100,
        scopedSlots: { customRender: 'action' },
    },
];

const data = [];

export default {
    data() {
        return {
            level: level,
            data,
            columns,
            visible: false,
            form: {
                isAdmin: 0,
                adminXX: ['广东省', '广州市', '天河区']
            },
            config: {},
            nickName: '',
            phone: '',
            time: 0,
            curItem: {},
            curIndex: -1,
        };
    },
    mounted() {
        let obj = {
            nickName: '',//标题搜索
            phone: '',
            time: 0,
        };
        this.loadxx(obj);
    },

    methods: {
        submit(e) {
            console.log(this.curItem)
        },
        onChange1(e) {
            console.log(e)
            this.adminXX = e
        },
        onChange2(e) {
            this.isAdmin = e;
            console.log(e)
        },
        toSearch() {
            let obj = {
                nickName: this.nickName,//标题搜索
                phone: this.phone,
                time: this.$moment(this.time).valueOf(),
            };
            console.log(obj);
            this.loadxx(obj);
        },
        loadxx(obj) {
            this.data = [];
            this.$axios({
                method: 'post',
                url: '/getWebUser',
                headers: {
                    authorization: localStorage.getItem('token')
                },
                data: {
                    ...obj
                }
            }).then(res => {
                console.log(res);
                if (res.data.code == 200) {
                    res.data.data.data.forEach((ele, index) => {
                        ele.index = index + 1;
                        ele.timec = this.getNowTime(ele.registrationTime);
                    })
                    this.data = res.data.data.data
                } else {
                    message.error(res.data.data.errMsg);
                }

            })
        },
        //获取当前时间
        getNowTime(timec) {
            if (Number.isNaN(Number(timec))) {
                return timec
            }
            var date = new Date(Number(timec));
            //年 getFullYear()：四位数字返回年份
            var year = date.getFullYear(); //getFullYear()代替getYear()
            //月 getMonth()：0 ~ 11
            var month = date.getMonth() + 1;
            //日 getDate()：(1 ~ 31)
            var day = date.getDate();
            //时 getHours()：(0 ~ 23)
            var hour = date.getHours();
            //分 getMinutes()： (0 ~ 59)
            var minute = date.getMinutes();
            //秒 getSeconds()：(0 ~ 59)
            var second = date.getSeconds();

            var time = year + '-' + this.addZero(month) + '-' + this.addZero(day) + ' ' + this.addZero(hour) + ':' + this.addZero(minute) + ':' + this.addZero(second);
            return time;
        },
        //小于10的拼接上0字符串
        addZero(s) {
            return s < 10 ? ('0' + s) : s;
        },
        showModal(text, a, b) {
            console.log(text, a, b)
            this.curItem = text;
            this.curIndex = b;
            this.form.adminXX=[];
            this.form.isAdmin=[];
            this.form.isAdmin = text.isAdmin == 0 ? false : true;
            // this.form.adminXX = ['河南省','周口市','郸城县'];
            this.form.adminXX = [text.province,text.city,text.area];
            console.log(this.form.adminXX)
            this.visible = true;
        },
        hideModal() {
            console.log(this.form)
            let obj = {
                adminXX: this.form.adminXX, isAdmin: this.form.isAdmin, openid: this.curItem.openid
            }
            this.$axios({
                method: 'post',
                url: '/setWebUser',
                headers: {
                    authorization: localStorage.getItem('token')
                },
                data: {
                    ...obj
                }
            }).then(res => {
                console.log(res);
                if (res.data.code == 200) {
                    this.visible = false;
                    this.curItem = {};
                    this.data[this.curIndex].isAdmin = this.form.isAdmin;
                } else {
                    message.error(res.data.data.errMsg);
                }

            })
        },
    },
};
</script>
<style></style>
  