<template>
    <div>
        <div
            style="display: flex;justify-content: space-around;margin: 10px 0;background-color: #fff;padding: 20px 0 ;border-radius: 4px;">
            <div><a-input placeholder="请输入商铺名称" v-model="shopNames" /></div>
            <div>发布时间：
                <a-date-picker v-decorator="['date-time-picker', config]" v-model="time" show-time
                    format="YYYY-MM-DD HH:mm:ss" />
            </div>
            <a-select default-value="99" style="width: 120px" @change="handleChange">
                <a-select-option value="店面">
                    店面
                </a-select-option>
                <a-select-option value="网店">
                    网店
                </a-select-option>
                <a-select-option value="摊位">
                    摊位
                </a-select-option>
                <a-select-option value="技术支持">
                    技术支持
                </a-select-option>
                <a-select-option value="99">
                    全部
                </a-select-option>
            </a-select>
            <a-button slot="enterButton" type="primary" @click="toSearch">
                搜索
            </a-button>
        </div>

        <br /><br />

        <a-table :data-source="data" :scroll="{ x: 1500, y: 600 }">
            <a-table-column key="index" title="序号" data-index="index" />
            <a-table-column key="shopNames" data-index="shopNames" title="商铺名称">
                <template slot-scope="tags,ele,index">
                    <img :src="ele.shopPhoto | imgFilter" alt=""
                        style="width:40px;height: 40px;margin-right: 10px;background-color: red;cursor: pointer;border-radius: 4px;">
                    <span>
                        {{ tags }}
                    </span>
                </template>
            </a-table-column>
            <a-table-column key="storeMain" title="店铺主营" data-index="storeMain" />
            <a-table-column key="storePhone" title="店铺号码" data-index="storePhone" />
            <a-table-column key="timec" title="注册时间" data-index="timec" />
            <!-- <a-table-column key="timec" title="有效时间" data-index="timec" /> -->
            <a-table-column key="address" title="地址" data-index="address" />
            <a-table-column key="type" title="类型" data-index="type">
                <template slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys }">
                    <div style="background-color: white; border: solid;">
                        <a-checkbox v-for="tag in ['店面', '网店', '摊位', '技术支持']" :key="tag"
                            :checked="filteredTags.includes(tag)"
                            @change="filteredTags = toggleSelectedTags(filteredTags, tag, $event.target.checked)">
                            {{ tag }}
                        </a-checkbox>
                    </div>
                </template>
                <template slot-scope="tags">
                    <span>
                        <a-tag color="blue">{{ tags }}</a-tag>
                    </span>
                </template>
            </a-table-column>
            <a-table-column key="4532" title="商品信息" data-index="topicClassification">
                <template slot-scope="tags ,ele,index">
                    <div v-for="tag in tags" :key="tag.uuid">
                        <div style="padding:5px 0 ;"> 商品主题 ： {{ tag.value }}</div>
                        <div>
                            <div v-for="tagc in tag.children" :key="tag.uuid">
                                <img :src="tagc.rotationChart[0] | imgFilter" alt="" @click="lookShop(tagc)"
                                    style="width:40px;height: 40px;margin-right: 10px;background-color: red;cursor: pointer;border-radius: 4px;">
                            </div>
                        </div>
                    </div>
                </template>
            </a-table-column>
        </a-table>

        <!-- 遮罩层 -->
        <a-drawer :visible="visible" title="商品详情" @close="hideModal" width="40%">
            <a-carousel arrows style="height: 180px;"  id="ccc">
                <div v-for="(tagc, index) in curItem.rotationChart" :key="index" style="height: 180px;">
                    <img :src="tagc | imgFilter"
                        style="width:100%;height: 160px;margin-right: 10px;background-color: red;cursor: pointer;border-radius: 4px;" />
                </div>
                
            </a-carousel>
            <div>
                <div style="color: #333;font-size: 26px;">{{ curItem.shopNames }}</div>
                <div style="color: #e4393c;font-size: 28px;">￥ {{ curItem.shopPrice }}</div>
            </div>
            <div style="margin: 10px 0;background-color: #ddd;padding: 4px;border-radius: 5px;">
                <div style="padding-left: 10px;">商品属性</div>
                <div v-for="(tagc, index) in curItem.productParameter" :key="index" 
                    style="display: flex;justify-content: space-between;flex-wrap: wrap;margin: 10px 0;border-bottom: 1px dotted #fff;">
                    <div style="padding-left:3% ;">{{ tagc.key }} :</div>
                    <div style="padding-right:3% ;">{{ tagc.value }}</div>
                </div>
            </div>
            <div>
                <div v-for="(tagc, index) in curItem.productDetails" :key="index">
                    <img :src="tagc  | imgFilter"
                        style="width:100%;height: auto;margin-right: 10px;background-color: red;cursor: pointer;" />
                </div>
            </div>
        </a-drawer>
    </div>
</template>
<script>

const columns = [
    { title: '序号', width: 100, dataIndex: 'index', key: 'index', fixed: 'left' },
    { title: '商铺名称', width: 200, dataIndex: 'shopNames', key: 'shopNames', fixed: 'left', scopedSlots: { customRender: 'shopNamesAll' } },
    { title: '店铺类型', dataIndex: 'type', key: 'type', width: 150, scopedSlots: { customRender: 'type' } },
    { title: '店铺主营', dataIndex: 'storeMain', key: 'storeMain', width: 150 },
    { title: '店铺电话', dataIndex: 'storePhone', key: 'storePhone', width: 150 },
    { title: '店铺地址', dataIndex: 'address', key: 'address', width: 150 },
    { title: '认证状态', dataIndex: 'isAuth', key: 'isAuth', width: 150 },
    { title: '发布时间', dataIndex: 'timec', key: 'timec', width: 150 },
    { title: '商铺商品信息', dataIndex: 'topicClassification', key: 'topicClassification', width: 150, scopedSlots: { customRender: 'action' } },
    // {
    //     title: '操作',
    //     key: 'operation',
    //     fixed: 'right',
    //     width: 100,
    //     scopedSlots: { customRender: 'action' }
    // },
];

const data = [];

export default {
    data() {
        return {
            data,
            columns,
            visible: false, form: { name: '', pas: '', pas1: '' },
            shopNames: '',
            time: '',
            config: {},
            filteredTags: [],
            tagsFilterDropdownVisible: false,
            curItem: {},
            type:''
        };
    },
    created() {
        let obj = {
            shopNames: '',//标题搜索
            time: 0,
            type:''
        };
        this.loadxx(obj)
    },
    methods: {
        handleChange(e){
            this.type=e;
            console.log(e)
        },
        lookShop(ele) {
            this.curItem = ele;
            this.visible = true;
            console.log(ele)
        },
        toSearch() {
            let obj = {
                shopNames: this.shopNames,
                time: this.$moment(this.time).valueOf() || 0,
                type: this.type==99?'':this.type,
            };
            console.log(obj);
            this.loadxx(obj);
        },
        loadxx(obj) {
            this.data = [];
            this.$axios({
                method: 'post',
                url: '/getWebShop',
                headers: {
                    authorization: localStorage.getItem('token')
                },
                data: {
                    ...obj
                }
            }).then(res => {
                console.log(res);
                if (res.data.code == 200) {
                    res.data.data.data.forEach((ele, index) => {
                        ele.index = index + 1;
                        ele.timec = this.getNowTime(ele.createTime);
                        // console.log(ele.timec)
                    })
                    this.data = res.data.data.data;
                    console.log(this.data)
                } else {
                    message.error(res.data.data.errMsg);
                }

            })
        },
        //获取当前时间
        getNowTime(timec) {
            if (Number.isNaN(Number(timec))) {
                return timec
            }
            var date = new Date(Number(timec));
            //年 getFullYear()：四位数字返回年份
            var year = date.getFullYear(); //getFullYear()代替getYear()
            //月 getMonth()：0 ~ 11
            var month = date.getMonth() + 1;
            //日 getDate()：(1 ~ 31)
            var day = date.getDate();
            //时 getHours()：(0 ~ 23)
            var hour = date.getHours();
            //分 getMinutes()： (0 ~ 59)
            var minute = date.getMinutes();
            //秒 getSeconds()：(0 ~ 59)
            var second = date.getSeconds();

            var time = year + '-' + this.addZero(month) + '-' + this.addZero(day) + ' ' + this.addZero(hour) + ':' + this.addZero(minute) + ':' + this.addZero(second);
            return time;
        },
        //小于10的拼接上0字符串
        addZero(s) {
            return s < 10 ? ('0' + s) : s;
        },
        filterOption(inputValue, option) {
            return option.description.indexOf(inputValue) > -1;
        },
        // handleChange(targetKeys, direction, moveKeys) {
        //     console.log(targetKeys, direction, moveKeys);
        //     this.targetKeys = targetKeys;
        // },
        handleSearch(dir, value) {
            console.log('search:', dir, value);
        },
        showModal() {
            console.log(this.form)
            this.visible = true;
            console.log(this.form)
        },
        hideModal() {
            console.log(this.form)
            this.visible = false;
        },
        onSearch(value) {
            console.log(value);
        },
        newUser() {
            console.log('西能用户')
            this.visible = true;
        },
        toggleSelectedTags(oldTags, tag, checked) {
            let newTags = [...oldTags];
            if (checked) {
                newTags.push(tag);
            } else {
                newTags = newTags.filter(t => t != tag);
            }
            return newTags;
        },
    },
};
</script>
<style scoped>
/* For demo */
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
#ccc  div{
    height: 240px;
}
</style>
  