import axios from 'axios';
import { loading, message } from 'ant-design-vue';
// 配置默认的 host, 假如你的 API host 是: http://api.htmlx.club
// axios.defaults.baseURL = 'http://127.0.0.1:3000/v1/web';
// axios.defaults.baseURL = 'http://111.230.12.167/v1/web';  //https://a.bendijia.cn/#/login
axios.defaults.baseURL = 'https://a.bendijia.cn/v1/web'// 'http://116.62.61.217/v1/web';
axios.defaults.timeout = 5000;
// message.success('This is a success message');
var loadinginstace;

// 添加请求拦截器
axios.interceptors.request.use(function(config) {
    console.log(config);
    loadinginstace = message.loading('正在请求服务器...', 0);
    setTimeout(loadinginstace, 1000);
    // 在发送请求之前做些什么
    return config
}, function(error) {
    // 对请求错误做些什么
    loadinginstace = message.loading('加载超时', 0);
    setTimeout(loadinginstace, 1000);
    return Promise.reject(error)
});


// 添加响应拦截器
axios.interceptors.response.use(function(response) {
    // 对响应数据做点什么
    console.log(response)
    return response
}, function(error) {
    // 对响应错误做点什么
    loadinginstace = message.loading('加载失败', 0);
    setTimeout(loadinginstace, 1000);
    return Promise.reject(error)
});