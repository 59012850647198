import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue';
// import iview from 'iview'
// import 'iview/dist/styles/iview.css'
import 'ant-design-vue/dist/antd.css';
import '@wangeditor/editor/dist/css/style.css';
{/* <style src="@wangeditor/editor/dist/css/style.css"></style> */}
import axios from 'axios'
import './lib/axios.js' //axios.js 的路径

//引入
import moment from "moment"; //挂载到原型
Vue.prototype.$moment = moment; //汉化，否则显示日期格式是国外的日期格式
moment.locale("zh-CN");



// Vue.use(iview)
Vue.use(Antd);
Vue.prototype.$axios = axios;

Vue.config.productionTip = false

Vue.filter('imgFilter', (url) => {
    console.log(url)
    return window.location.protocol + '//' + window.location.hostname + '/v1/func/img?url=' + url;
    // return window.location.protocol + '//' + window.location.hostname + '/v1/func/img?url=' + url;
    console.log('65656556', url)
})

router.beforeEach((to, from, next) => {
    next()
        // if (to.path == '/login') {
        //     sessionStorage.removeItem('user');
        // }
        // let user = JSON.parse(sessionStorage.getItem('user'));
        // if (!user && to.path != '/login') {
        //     next({ path: '/login' })
        // } else {
        //     next()
        // }
})

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')