<template>
    <div>
        <div
            style="display: flex;justify-content: space-around;margin: 10px 0;background-color: #fff;padding: 20px 0 ;border-radius: 4px;">
            <div><a-input placeholder="请输入出发地" v-model="placeOfDeparture" /></div>
            <div><a-input placeholder="请输入目的地" v-model="destination" /></div>
            <div><a-input placeholder="请输入联系人" v-model="contacts" /></div>
            <a-select default-value="99" style="width: 120px" @change="handleChange">
                <a-select-option value="人找车">
                    人找车
                </a-select-option>
                <a-select-option value="车找人">
                    车找人
                </a-select-option>
                <a-select-option value="代驾">
                    代驾
                </a-select-option>
                <a-select-option value="货找车">
                    货找车
                </a-select-option>
                <a-select-option value="车找货">
                    车找货
                </a-select-option>
                <a-select-option value="99">
                    全部
                </a-select-option>
            </a-select>
            <div>过期时间：
                <a-date-picker v-decorator="['date-time-picker', config]" v-model="time" show-time
                    format="YYYY-MM-DD HH:mm:ss" />
            </div>
            <a-button type="primary" html-type="submit" @click="toSearch">
                搜索
            </a-button>
        </div>
        <a-table :data-source="data" :bordered="bordered" rowKey="uuid" :scroll="{ x: 1500, y: 600 }">
            <a-table-column key="index" title="序号" data-index="index" />
            <a-table-column key="placeOfDeparture" data-index="placeOfDeparture">
                <span slot="title" style="color: #1890ff">出发地</span>
            </a-table-column>
            <a-table-column key="destination" title="目的地" data-index="destination" />
            <a-table-column key="contacts" title="联系人" data-index="contacts" />
            <a-table-column key="phone" title="电话" data-index="phone" />
            <a-table-column key="timec" title="出发时间" data-index="timec" />
            <a-table-column key="vehicleModel" title="车型" data-index="vehicleModel" />
            <a-table-column key="numberOfPeople" title="空位/人数" data-index="numberOfPeople" />
            <a-table-column key="road" title="途径" data-index="road" />
            <a-table-column key="remake" title="备注" data-index="remake" />
            <a-table-column key="type" title="标签" data-index="type">
                <template slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys }">
                    <div style="background-color: white; border: solid;">
                        <a-checkbox v-for="tag in ['人找车', '车找人', '货找车', '车找货']" :key="tag"
                            :checked="filteredTags.includes(tag)"
                            @change="filteredTags = toggleSelectedTags(filteredTags, tag, $event.target.checked)">
                            {{ tag }}
                        </a-checkbox>
                    </div>
                </template>
                <template slot-scope="tags">
                    <span>
                        <a-tag color="blue">{{ tags }}</a-tag>
                    </span>
                </template>
            </a-table-column>
            <!-- <a-table-column key="action" title="功能">
            <template slot-scope="text, record">
                <span>
                    <a>审核</a>
                    <a-divider type="vertical" />
                    <a>删除</a>
                </span>
            </template>
        </a-table-column> -->
        </a-table>
    </div>
</template>
<script>
const data = [
    {
        key: '1',
        firstName: 'John',
        lastName: 'Brown',
        age: 32,
        address: 'New York No. 1 Lake Park',
        tags: ['网店'],
    }
];

export default {
    data() {
        return {
            data,
            filteredTags: [],
            tagsFilterDropdownVisible: false,
            placeOfDeparture: '',
            destination: '',
            contacts: '',
            time: '',
            bordered: true,
            type:'',
            config: {
                // rules: [{ type: 'object', required: true, message: '1231232' }],
            },
        };
    },
    mounted() {
        let obj = {
            placeOfDeparture: '',//标题搜索
            destination: '',
            contacts: '',
            time: 0,
            type:''
        };
        this.loadxx(obj);
    },
    methods: {
        toggleSelectedTags(oldTags, tag, checked) {
            let newTags = [...oldTags];
            if (checked) {
                newTags.push(tag);
            } else {
                newTags = newTags.filter(t => t != tag);
            }
            return newTags;
        },
        handleChange(e){
            this.type=e;
            console.log(e)
        },
        toSearch() {
            let obj = {
                placeOfDeparture: this.placeOfDeparture,//标题搜索
                contacts: this.contacts,
                destination: this.destination,
                time: this.$moment(this.time).valueOf(),
                type: this.type==99?'':this.type,
            };
            console.log(obj);
            this.loadxx(obj);
        },
        loadxx(obj) {
            this.data = [];
            this.$axios({
                method: 'post',
                url: '/getWebtravel',
                headers: {
                    authorization: localStorage.getItem('token')
                },
                data: {
                    ...obj
                }
            }).then(res => {
                console.log(res);
                if (res.data.code == 200) {
                    res.data.data.data.forEach((ele, index) => {
                        ele.index = index + 1;
                        ele.timec = this.getNowTime(ele.time);
                    })
                    this.data = res.data.data.data
                } else {
                    message.error(res.data.data.errMsg);
                }

            })
        },
        //获取当前时间
        getNowTime(timec) {
            if (Number.isNaN(Number(timec))) {
                return timec
            }
            var date = new Date(Number(timec));
            //年 getFullYear()：四位数字返回年份
            var year = date.getFullYear(); //getFullYear()代替getYear()
            //月 getMonth()：0 ~ 11
            var month = date.getMonth() + 1;
            //日 getDate()：(1 ~ 31)
            var day = date.getDate();
            //时 getHours()：(0 ~ 23)
            var hour = date.getHours();
            //分 getMinutes()： (0 ~ 59)
            var minute = date.getMinutes();
            //秒 getSeconds()：(0 ~ 59)
            var second = date.getSeconds();

            var time = year + '-' + this.addZero(month) + '-' + this.addZero(day) + ' ' + this.addZero(hour) + ':' + this.addZero(minute) + ':' + this.addZero(second);
            return time;
        },
        //小于10的拼接上0字符串
        addZero(s) {
            return s < 10 ? ('0' + s) : s;
        },
    },
};
</script>
  