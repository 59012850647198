<template>
    <div style="width:100%;height:100%;">
        <a-layout id="components-layout-demo-custom-trigger" style="height:100%;">
            <a-layout-sider v-model="collapsed" :trigger="null" collapsible style="height:100%;">
                <div class="logo">
                    <span v-if="!collapsed">管理系统</span>
                    <span v-else></span>
                </div>

                <a-menu theme="dark" mode="inline" :default-selected-keys="arrc" :default-open-keys="['sub0']">
                    <!-- <a-sub-menu key="sub4">
                        <span slot="title"><a-icon type="rise" /><span>汇总</span></span>
                        <a-menu-item key="01">
                            <router-link to="/home">汇总</router-link>
                        </a-menu-item>
                    </a-sub-menu> -->
                    <a-sub-menu key="sub0">
                        <span slot="title"><a-icon type="project" /><span>管理信息</span></span>
                        <a-menu-item key="1">
                            <router-link to="/home">汇总</router-link>
                        </a-menu-item>
                        <a-menu-item key="2">
                            <router-link to="/one">信息列表</router-link>
                        </a-menu-item>
                        <a-menu-item key="3">
                            <router-link to="/two">出行列表</router-link>
                        </a-menu-item>
                        <a-menu-item key="4">
                            <router-link to="/six">商铺列表</router-link>
                        </a-menu-item>
                        <a-menu-item key="5">
                            <router-link to="/three">用户管理</router-link>
                        </a-menu-item>
                        <a-menu-item key="65">
                            <router-link to="/xx">信息协议</router-link>
                        </a-menu-item>
                    </a-sub-menu>
                    <!-- <a-sub-menu key="sub1">
                        <span slot="title"><a-icon type="team" /><span>管理</span></span>
                        <a-menu-item key="25">
                            <router-link to="/three">用户管理</router-link>
                        </a-menu-item>
                        <a-menu-item key="26">
                            <router-link to="/four">授权管理</router-link>
                        </a-menu-item>
                    </a-sub-menu>
                    <a-sub-menu key="sub2">
                        <span slot="title"><a-icon type="shop" /><span>商品</span></span>
                        <a-menu-item key="35">
                            <router-link to="/five">新增商品</router-link>
                        </a-menu-item>
                        <a-menu-item key="36">
                            <router-link to="/six">商铺列表</router-link>
                        </a-menu-item>
                    </a-sub-menu> -->

                </a-menu>
            </a-layout-sider>
            <a-layout style="height:100%;">
                <a-layout-header
                    style="background: #fff; padding: 0;display: flex;justify-content: space-between;border-bottom: 1px solid #ccc;">
                    <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                        @click="() => (collapsed = !collapsed)" />
                    <div style="margin-right:20px;">
                        <span style="margin-right:20px;">{{ name }}</span>
                        <a-button @click="tologin" style="background-color: #40a9ff;color:#fff;">
                            退出登录
                        </a-button>
                    </div>
                </a-layout-header>
                <a-layout-content
                    :style="{ margin: '24px 16px', padding: '24px', minHeight: '280px', 'border-radius': '10px' }">
                    <router-view></router-view>
                </a-layout-content>
            </a-layout>
        </a-layout>
    </div>
</template>

<script>
export default {
    created() {
        console.log(this.$router.options, this)
    },
    created(){
        let hash = window.location.hash;
        let str = [];
        if (hash.includes('three')) {
            str = ['5']
        } else if (hash.includes('home')) {
            str = ['1']
        } else if (hash.includes('one')) {
            str = ['2']
        } else if (hash.includes('two')) {
            str = ['3']
        } else if (hash.includes('six')) {
            str = ['4']
        }
        this.arrc = str;
    },
    mounted() {
                
    },
    data() {
        return {
            name: '用户名',
            collapsed: false,
            openNames: [this.$route.matched[0].name],
            // curUserName: sessionStorage.getItem('user').replace(/\"/g, ""),
            modeType: "vertical",
            spanLeft: 4,
            spanRight: 20,
            logoIsDisplay: false,
            loading: true,
            modal1: false,
            arrc: ['0'],
            formValidate: {
                oldPassword: '',
                newPassword: '',
                resetPassword: ''
            },
            ruleValidate: {
                oldPassword: [
                    { required: true, message: '密码不能为空', trigger: 'blur' }
                ],
                newPassword: [
                    { required: true, message: '密码不能为空', trigger: 'blur' }
                ],
                resetPassword: [
                    { required: true, message: '密码不能为空', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        tsc(e) {
            console.log(this.collapsed)
            this.collapsed = !this.collapsed
        },
        tologin() {
            let url = window.location.origin + window.location.pathname + '#/login';
            console.log(url)
            window.location.replace(url)
        },
        toggleClick() {
            if (this.spanLeft === 4) {
                this.spanLeft = 1;
                this.spanRight = 23;
            } else {
                this.spanLeft = 4;
                this.spanRight = 20;
            }
        },
        modifyPassWord() {
            this.modal1 = true;
        },
        logout() {
            this.$router.push('/login');
        },
        comfirmModifyPS() {
            return false;
            this.$refs.formValidate.validate((valid) => {
                if (valid) {
                    this.modal1 = false;
                    //         this.loading = false;
                    this.$Message.success('提交成功!');
                } else {
                    this.$Message.error('表单验证失败!');
                    return false;
                }
            })
            // this.$Message.info('点击了确定');
        },
        cancel() {
            this.modal1 = false;
            this.$Message.info('点击了取消');
        },
        menuSelect(name) {
            this.$router.push({ path: name });
        },
        dropDown(name) {
            this.$router.push({ path: name });
            console.log(name);
        }

    },
}
</script>
<style scoped>
#components-layout-demo-custom-trigger .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
    height: 100%;
}

#components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
    height: 32px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    letter-spacing: 16px;
    margin: 16px;
}
</style>
