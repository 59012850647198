<template>
    <div class="body">
        <section class="sectionv">
            <span v-for="item  in  198" class="clockhover"></span>
            <div class="signin">
                <div class="content">
                    <h2>登 录</h2>
                    <div class="form">
                        <div class="input-box">
                            <input type="text" v-model="name" required>
                            <i>用户名</i>
                        </div>
                        <div class="input-box">
                            <input type="password" v-model="pwd" required>
                            <i>密码</i>
                        </div>
                        <!-- <div class="links">
                            <a href="#">Forgot Password</a>
                            <a href="#">Singup</a>
                        </div> -->
                        <div class="input-box">
                            <Vcode :show="isShow" @success="success" @close="close" />
                            <input type="submit" @click="submit" value="登陆">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
  
<script>
import { message } from 'ant-design-vue';
import Vcode from "vue-puzzle-vcode";
export default {
    data() {
        return {
            name: '',
            pwd: '',
            formLayout: 'horizontal',
            form: this.$form.createForm(this, { name: 'coordinated' }),
            isShow: false, // 验证码模态框是否出现
        };
    },
    components: {
        Vcode
    },
    methods: {
        aa() {
            console.log(this)
        },
        submit() {
            console.log(12312432, this.name, this.pwd);
            if (this.name.trim().length == 0) {
                message.error('用户名不能为空');
                return false;
            }
            if (this.pwd.trim().length == 0) {
                message.error('密码不能为空');
                return false
            }
            this.isShow = true;
        },
        // 用户通过了验证
        success(msg) {
            // 需要去登陆
            console.log('去登录', this);
            this.isShow = false; // 通过验证后，需要手动隐藏模态框
            this.$axios({
                method: 'post',
                url: '/login',
                data: {
                    'userName': this.name,
                    'password': this.pwd
                }
            }).then(res => {
                console.log(res,res.data.data);
                if (res.data.code == 200) {
                    localStorage.setItem('token', res.data.data.token);
                    localStorage.setItem('name', res.data.data.userName);

                    let url = window.location.origin + window.location.pathname + '#/home';
                    console.log(url)
                    window.location.replace(url)
                } else {
                    message.error(res.data.data.errMsg);
                }

            })

        },
        // 用户点击遮罩层，应该关闭模态框
        close() {
            this.isShow = false;
            let url = window.location.origin + window.location.pathname + '#/home';
            console.log(url)
            window.location.replace(url)
        },
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    console.log('Received values of form: ', values);
                }
            });
        },
        handleSelectChange(value) {
            console.log(value);
            this.form.setFieldsValue({
                note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`,
            });
        },
    },
};
</script>
<style>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.body {
    font-family: sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #000;
}

.sectionv {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    gap: 2px;
    flex-wrap: wrap;
    overflow: hidden;
}

.sectionv::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(#000, #0f0, #000);
    animation: animate 5s linear infinite;
}

@keyframes animate {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(100%);
    }
}

.sectionv span {
    position: relative;
    display: block;
    background-color: #181818;
    width: calc(6.25vw - 2px);
    height: calc(6.25vw - 2px);
    z-index: 2;
    transition: 1.5s;
}

.sectionv span:hover {
    background-color: #0f0;
    transition: 0s;
}

.sectionv .signin {
    position: absolute;
    width: 400px;
    background-color: #222;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    border-radius: 4px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
    z-index: 900;
}

.sectionv .signin .content {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
}

.sectionv .signin .content h2 {
    font-size: 2em;
    color: #0f0;
    text-transform: uppercase;
}

.sectionv .signin .content .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.sectionv .signin .content .form .input-box {
    position: relative;
    width: 100%;
}

.sectionv .signin .content .form .input-box input {
    position: relative;
    width: 100%;
    background-color: #333;
    border: none;
    outline: none;
    padding: 25px 10px 7.5px;
    border-radius: 4px;
    color: #fff;
    font-size: 1em;
    font-weight: 500;
}

.sectionv .signin .content .form .input-box i {
    position: absolute;
    left: 0;
    padding: 15px 10px;
    font-style: normal;
    color: #aaa;
    transition: 0.5s;
    pointer-events: none;
}

.signin .content .form .input-box input:focus~i,
.signin .content .form .input-box input:valid~i {
    transform: translateY(-7.5px);
    font-size: 0.5em;
    color: #fff;
}

.signin .content .form .links {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.signin .content .form .links a {
    color: #fff;
    text-decoration: none;
}

.signin .content .form .links a:last-child {
    color: #0f0;
    font-weight: 600;
}

.signin .content .form .input-box input[type="submit"] {
    padding: 10px;
    background-color: #0f0;
    color: #111;
    font-weight: 600;
    font-size: 1.25em;
    letter-spacing: 0.05em;
    cursor: pointer;
}

@media (max-width: 900px) {
    section span {
        width: calc(10vw - 2px);
        height: calc(10vw - 2px);
    }
}

@media (max-width: 600px) {
    section span {
        width: calc(20vw - 2px);
        height: calc(20vw - 2px);
    }
}
</style>