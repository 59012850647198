<template>
    <div>
        <a-input-search placeholder="请输入姓名" style="width:100%;" enter-button @search="onSearch" />
        <br /><br />

        <a-table :columns="columns" :data-source="data" :scroll="{ x: 1500, y: 300 }">
            <a slot="action" slot-scope="text">
                <span @click="newUser">授权</span> | <span>删除 </span>
            </a>
        </a-table>

        <!-- 遮罩层 -->
        <a-modal v-model="visible" title="新建用户" ok-text="确认" cancel-text="取消" @ok="hideModal" width="60%">
            <a-transfer :list-style="{
                width: '46%',
                height: '100%',
            }" :data-source="mockData" show-search :filter-option="filterOption" :target-keys="targetKeys"
                :render="item => item.title" @change="handleChange" @search="handleSearch" />
        </a-modal>
    </div>
</template>
<script>
const columns = [
    { title: 'Full Name', width: 100, dataIndex: 'name', key: 'name', fixed: 'left' },
    { title: 'Age', width: 100, dataIndex: 'age', key: 'age', fixed: 'left' },
    { title: 'Column 1', dataIndex: 'address', key: '1', width: 150 },
    { title: 'Column 2', dataIndex: 'address', key: '2', width: 150 },
    { title: 'Column 3', dataIndex: 'address', key: '3', width: 150 },
    { title: 'Column 4', dataIndex: 'address', key: '4', width: 150 },
    { title: 'Column 5', dataIndex: 'address', key: '5', width: 150 },
    { title: 'Column 6', dataIndex: 'address', key: '6', width: 150 },
    { title: 'Column 7', dataIndex: 'address', key: '7', width: 150 },
    { title: 'Column 8', dataIndex: 'address', key: '8' },
    {
        title: 'Action',
        key: 'operation',
        fixed: 'right',
        width: 100,
        scopedSlots: { customRender: 'action' },
        mockData: [],
        targetKeys: [],
    },
];

const data = [];
for (let i = 0; i < 100; i++) {
    data.push({
        key: i,
        name: `Edrward ${i}`,
        age: 32,
        address: `London Park no. ${i}`,
        formLayout: 'horizontal',
    });
}

export default {
    data() {
        return {
            data,
            columns,
            visible: false, form: { name: '', pas: '', pas1: '' }
        };
    },
    created() {
        this.getMock();
    },
    methods: {
        getMock() {
            const targetKeys = [];
            const mockData = [];
            for (let i = 0; i < 20; i++) {
                const data = {
                    key: i.toString(),
                    title: `未接管区域信息${i + 1}`,
                    description: `已接管区域信息${i + 1}`,
                    chosen: Math.random() * 2 > 1,
                };
                if (data.chosen) {
                    targetKeys.push(data.key);
                }
                mockData.push(data);
            }
            this.mockData = mockData;
            this.targetKeys = targetKeys;
        },
        filterOption(inputValue, option) {
            return option.description.indexOf(inputValue) > -1;
        },
        handleChange(targetKeys, direction, moveKeys) {
            console.log(targetKeys, direction, moveKeys);
            this.targetKeys = targetKeys;
        },
        handleSearch(dir, value) {
            console.log('search:', dir, value);
        },
        showModal() {
            console.log(this.form)
            this.visible = true;
            console.log(this.form)
        },
        hideModal() {
            console.log(this.form)
            this.visible = false;
        },
        onSearch(value) {
            console.log(value);
        },
        newUser() {
            console.log('西能用户')
            this.visible = true;
        }
    },
};
</script>
<style></style>
  